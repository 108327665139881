<template>

<Overlay  :language="language" v-if="displayModal" :favorite="false" :title="title" :visible="displayModal" @remove="displayModal=false"/>

<div class="confirm-wrapper"  v-if="loggedIn">
    

     <content-loader  v-if="loaded==false"
            viewBox="0 0 520 250"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="10" y="20" rx="11" ry="11" width="500" height="22" />
            
            <rect x="10" y="70" rx="11" ry="11" width="500" height="22" />

            <rect x="10" y="130" rx="11" ry="11" width="500" height="22" />

            <rect x="10" y="180" rx="11" ry="11" width="500" height="22" />

            
        </content-loader>
    
    
        <CheckoutOrderReview
            :language="language"
            :loaded="loaded"
            :cart_items="cart_items"
            :photos="photos"
            :promotionError="promotionError"
            :displayPromotion="displayPromotion"
            :total="total"
            :amount_promotion="amount_promotion"
            :confirmation="true"
            :order="order"

            @clickToggleReviewEv="clickToggleReview"
        
        />

        <div class="checkout coordonnees payment-confirmation" v-if="loaded">
            
            <img src="../../public/assets/images/check-big.png" width="52" /><br />
           
            <div class="title" v-if="hasOnMeasure==true">
                
                <span class="b" v-if="language=='fr'">Votre commande est enregistrée !</span>
                <span class="b" v-else>Your order is confirmed !</span>
                <br />
                <span v-if="language=='fr'">Commande</span><span v-else>Order</span> n° {{order.id}}<br />
                E-mail : {{order.user.email}}
               
            </div>

            <div class="title" v-else>
                <span v-if="language=='fr'" class="b">MERCI !<br />
                    Votre commande est confirmée !</span>
                    <span v-else class="b">THANK YOU!<br />
                   Your order is confirmed!</span>
                    <br /><br />
              
            </div>

            <div class="steps" v-if="hasOnMeasure==true">
                <div class="step" :class="{active:order.status=='completed' || order.status==null || order.status=='scanned' || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
                    <div class="image">
                        <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
                    </div>
                    <div class="step-name" v-if="language=='fr'">
                        Commande est enregistrée
                    </div>
                    <div class="step-name" v-else>
                       Your order is confirmed
                    </div>
                </div>
                <div class="step" :class="{active:order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
                    <div class="image">
                        <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
                    </div>
                    <div class="step-name" v-if="language=='fr'">
                        <span v-if="order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'">Scan reçu</span>
                        <span v-else>En attente de scan</span>

                    </div>
                    <div class="step-name" v-else>
                      
                       <span v-if="order.status=='scanned'  || order.status==null || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'">Scan received</span>
                        <span v-else>Waiting scan</span>

                    </div>
                   
                </div>
                <div class="step" :class="{active:order.status=='scanned' || order.status=='dispatched' || order.status=='printing' || order.status=='delivered'}">
                    <div class="image">
                        <div class="round"><img src="../../public/assets/images/check.png" width="8" /></div>
                    </div>

                    <div class="step-name" v-if="language=='fr'">
                        Production lancée
                    </div>
                    <div class="step-name" v-else>
                       In production
                    </div>
                   
                </div>
            </div>
          
            <div class="message" v-if="hasOnMeasure==true">
                <span v-if="language=='fr'">
                    {{ order.user.name }}, pour finaliser votre commande sur-mesure, un scan facial est nécessaire pour obtenir vos mensurations.
                </span>
                <span v-else>
                    {{ order.user.name }}, to finish your custom-fit order, a face scan is necessary to get your measures.
                </span>

            </div>
            <div class="message" v-else>
                <span v-if="language=='fr'">
                    {{ order.user.name }}, vos lunettes s’apprêtent a entrer en production. Elles seront imprimées en 3D, en France. Nous vous notifierons lorsqu’elles seront expédiées.
                </span>
                <span v-else>
                    {{ order.user.name }}, your glasses are ready to enter in production. They will be 3D printed, in France. We will notify you when they'll be dispatched.
                </span>
                
            </div>
            <br />
            <template v-if="(hasOnMeasure==true && (order.status=='scanned' || order.status=='dispatched' || order.status=='printing' || order.status=='delivered')) || hasOnMeasure==false">
                <a v-if="language=='fr'" :href="'/detail-commande/'+order.id">Suivre le statut de ma Commande</a>
                <a v-else :href="'/detail-commande/'+order.id">Check my order status</a>
            </template>
            <div class="order-steps" v-if="hasOnMeasure==true" >
                <div class="order-steps-title"><img class="arrow" src="../../public/assets/images/arrow2.png" />
                    <div v-if="language=='fr'" class="content">Accéder au scan facial
                        <div class="subtitle">À partir d'un iPhone ou un iPad avec Face ID.</div>
                        <div class="small">Téléchargez l'application Visages sur l'Apple Store depuis votre iPhone ou iPad, saisissez votre adresse e-mail et votre numéro de commande, puis suivez les instructions.</div>
                        <div  class="button-white">
                                <a class="contenumob"   href="https://apps.apple.com/fr/app/visages/id1632011793"  target="_blank">

                                    <img src="../../public/assets/images/apple-w.svg" width="25" > <span>Télécharger sur l'App Store</span>
                                </a>
                                <a class="contenudesk" @click="displayQrCode()"  target="_blank">

                                    <img src="../../public/assets/images/apple-w.svg" width="25" > <span>Télécharger sur l'App Store</span>
                                </a>
                        </div>
                       
                    </div>
                    <div v-else class="content">Access to the scan
                        <div class="subtitle">Grab an iPhone or iPad with Face ID</div>
                        <div class="small">Download the Visages application on the Apple Store from your iPhone or iPad, get your email and order number, and follow the instructions to get measured.</div>

                       
                        <div class="button-white">
                            <a class="contenumob"  href="https://apps.apple.com/fr/app/visages/id1632011793" target="_blank">
                                <img src="../../public/assets/images/apple-w.svg" width="25" > <span>Download on the App Store</span> 
                            </a>
                            <a class="contenudesk" @click="displayQrCode()">
                                <img src="../../public/assets/images/apple-w.svg" width="25" > <span>Download on the App Store</span> 
                            </a>
                        </div>

                    </div>
                </div>
                <!-- <div class="order-step" :class="{opened:displayStep1}" @click="displayStep1=!displayStep1">
                    <div v-if="language=='fr'"  class="title-step"><span class="num">1</span> <div class="content">TÉLÉCHARGER L'APP (LIEN)</div></div>
                    <div v-else class="title-step"><span class="num">1</span> <div class="content">DOWNLOAD APP (LIEN)</div></div>
                    
                    <div v-if="displayStep1" class="content-step">
                        <div  v-if="language=='fr'" class="button-white"><img src="../../public/assets/images/apple-w.svg" width="25" data-v-335e7e73=""> <span data-v-335e7e73="">Télécharger sur l'App Store</span></div>
                        <div v-else class="button-white"><img src="../../public/assets/images/apple-w.svg" width="25" data-v-335e7e73=""> <span data-v-335e7e73="">Download on the App Store</span></div>
                    </div>
                </div>
                <div class="order-step" :class="{opened:displayStep2}" @click="displayStep2=!displayStep2">
                    <div  v-if="language=='fr'" class="title-step"><span class="num">2</span> <div class="content">CONNEXION (IDENTIFIANT/CONNEXION)</div></div>
                    <div  v-else class="title-step"><span class="num">2</span> <div class="content">CONNEXION (ID/CONNEXION)</div></div>
                    <div v-if="displayStep2" class="content-step">Contenu</div>
                </div>
                <div class="order-step" :class="{opened:displayStep3}" @click="displayStep3=!displayStep3">
                    <div v-if="language=='fr'" class="title-step"><span class="num">3</span> <div class="content">SCAN VOTRE VISAGE SUIVANT LES INSTRUCTIONS</div></div>
                    <div v-else class="title-step"><span class="num">3</span> <div class="content">SCAN YOUR FACE BY FOLLOWING INSTRUCTIONS</div></div>
                    <div v-if="displayStep3" class="content-step">Contenu</div>
                </div>
                <div class="order-step" :class="{opened:displayStep4}" @click="displayStep4=!displayStep4">
                    <div v-if="language=='fr'" class="title-step"><span class="num">4</span> <div class="content">CONFIRMATION DE COMMANDE</div></div>
                    <div v-else class="title-step"><span class="num">4</span> <div class="content">ORDER CONFIRMATION</div></div>
                    <div v-if="displayStep4"  class="content-step">Contenu</div>
                </div> -->
            </div>
          
            <br /><br /><br /><br />
        </div>
    
      
    
     </div>
     <div   v-else>
        <br /><br /><br /><br /><br /><br />
        <center>
            <div v-if="language=='fr'"> Vous devez être connecté pour accéder à cette page</div>
            <div  v-else> You must be logged in to access this page</div></center>
            <br /><br /><br />
        </div>
     <Footer :language="language" :displayModalMenu="displayModalMenu" />
</template>

<script>
import Footer from '../components/layout/Footer.vue'
import {APIRequest,Woocommerce, conversionApi}  from '@simb2s/senseye-sdk';
import emitter from 'tiny-emitter/instance'
import sendinblue from 'sendinblue'
import fbq from 'fbq'
import { ContentLoader } from 'vue-content-loader'
import CheckoutOrderReview from "../components/children/Checkout/CheckoutOrderReviewComponent.vue"
import jQuery  from 'jquery';
import Overlay from "../components/overlay/OverlayComponent.vue"

export default {
  name: 'PaymentConfirmation',
  components: {
    CheckoutOrderReview,Footer,
    ContentLoader,
    Overlay
  },
  data() {
    return {
        order_id:null,
        order:null,
        title:'',
        timer:null,
        displayModal:false,
        loaded:false,
        loggedIn:false,
        cart_items:[],
        onlycollab:true,
        photos:[],
        displayStep1:false,
        hasOnMeasure:false,
        displayStep2:false,
        displayStep3:false,
        displayStep4:false,
        displayPromotion:false,
        total:0,
        amount_promotion:0
    }
  },
  props:["displayModalMenu","language"],
  methods:{
    showModal(title) {
        this.loaded = true
        this.displayModal = true;
        this.title = title;
    },
    displayQrCode() {
        this.showModal('<img src="'+require(`../../public/assets/images/qr-download.png`)+'" class="qr" width="300" />')
        
    },
    async checkOrderStatus(){
        let order=await APIRequest.detail('orders',this.$route.params.id)
        if(order.status!=this.order.status)
        {
            this.order.status=order.status
        }
    },
    
    formatPhoto(media) {
        let photo=media.replace('[','');
        photo=photo.replace(']','');
        photo=photo.replace("'",'');
        photo=photo.replace('"','');
        return photo
    },
    async formatSendInBlueItems(items) {
      let sib_items=[]
      for(let i=0;i<1;i++)
      {
        if(i==0)
        {
            if(items[i].glass) {
                let item_glass={}
                item_glass.id_glass=items[i].glass.id
                item_glass.price=items[i].glass.price
                item_glass.name=items[i].glass.name
                item_glass.quantity=items[i].quantity
                item_glass.id=items[i].id
                sib_items.push(item_glass)
           }
        }
      }
      return sib_items
    },

    clickToggleReview() {
        if(jQuery('.order-review #order-review-content').is(':visible'))
        {
            jQuery('.order-review h2.contenumob').removeClass('open');
            jQuery('.order-review #order-review-content').slideUp();
            jQuery('.order-review .subtotals').slideUp();
            jQuery('.order-review  .total').slideUp();
            jQuery('.review-elem').slideUp();
            jQuery('.order-review .message').slideUp();
        }
        else
        {
            jQuery('.order-review h2.contenumob').addClass('open');
            jQuery('.order-review #order-review-content').slideDown();
            jQuery('.order-review .subtotals').slideDown();
            jQuery('.order-review  .total').slideDown();
            jQuery('.order-review .message').slideDown();
            jQuery('.review-elem').slideDown();
        }
    },
  },
  updated()
  {
    if(this.cart_items.length)
    {
     
        for(let i=0;i<this.cart_items.length;i++)
        {
          

            if((this.cart_items[i].size=='onmeasure' && this.cart_items[i].scan==null) || (this.cart_items[i].scan==null && this.cart_items[i].size!='onesize' && this.cart_items[i].size!=null))
            {
               
                this.hasOnMeasure=true
            }
        }
    }
  },
  beforeUnmount() {
    clearInterval(this.timer)
    },


    
  async mounted()
  {
    
    if(localStorage.token_senseye_auth)
    {
        this.loggedIn=true
    

    //localStorage.removeItem('cart_senseye_id');
    //localStorage.removeItem('order_senseye_id');
    
    jQuery('.order-review h2.contenumob').click(function(){
        
        if(jQuery('#order-review-content').is(':visible'))
        {
            jQuery('#order-review-content').slideUp();
            jQuery('.review-elem').slideUp();
        }
        else
        {
            jQuery('#order-review-content').slideDown();
            jQuery('.review-elem').slideDown();
           
        }
    });
    this.loaded=false
    this.order=await APIRequest.detail('orders',this.$route.params.id)

    if(this.order.cart!=null) {
         this.cart_items=this.order.cart.cart_items
    }
    else {
        let request_new = '?order_object=' + this.order.id;
            
        let order_items_new=await APIRequest.list_with_request('order-items', request_new)
        let cart_items_new=[]
        for(let i=0;i<order_items_new[0].length;i++) {
            let item = await APIRequest.detail('order-items',order_items_new[0][i].id) 
            cart_items_new.push(item)
        }

        this.cart_items=cart_items_new
    }
    
   
    //await APIRequest.delete('carts',this.order.cart.id)

    this.total=this.order.amount.toFixed(2)

    if(this.order.promotion!=null) {
        this.displayPromotion=true
        this.amount_promotion=(this.total/100)*this.order.promotion.amount
        this.amount_promotion=this.amount_promotion.toFixed(2)
        if(this.order.promotion.amount==100)
        {
        
            this.total=0;
        }
        else {
            this.total=this.total-this.amount_promotion
        }
        
        this.total=this.total.toFixed(2)
    }
    
    let order_items=[];
    for(var i=0;i<this.cart_items.length;i++)
    {
      let order_item={};
      order_item.id=this.cart_items[i].id;
      order_items[i]=order_item;
    }
    localStorage.order_items_senseye=JSON.stringify(order_items);
                  
    

    var event_name = "order_completed"
    if(this.order.user!=null){
        var properties = {
            "email": this.order.user.email,
            "FIRSTNAME": this.order.user.name,
            "LASTNAME": this.order.user.surname,
            }
    }
   
    
    else {
        var properties = {
      "email": '',
      "FIRSTNAME": '',
      "LASTNAME": '',
    }
    }
    

    // console.log('sib items')
   

    if(this.cart_items.length>0)
    {
      
    
        let sib_items = await this.formatSendInBlueItems(this.cart_items)
        
        var event_data = {
        "id": this.order.id+"",
        "data": {
            "total": this.order.amount,
            "currency": "EUR",
            "url": "visages.com",
            "items": sib_items
        }
        }
        
        sendinblue.track(
        event_name, /*mandatory*/
        properties, /*optional*/
        event_data /*optional*/
        )

        // fbq('track', 'Purchase', {currency: "EUR", value: this.order.amount});
        // await conversionApi.addEvent('Purchase', this.order)

        let cart_items_datalayer=[]
        for(let i=0;i<this.cart_items.length;i++)
        {
        cart_items_datalayer.push({
            'name': this.cart_items[i].glass.name,      // name of a product that is displayed. Always include it.
            'id': this.cart_items[i].glass.id,     			// id of a product. Always include it.
            'price': this.cart_items[i].amount,				// price of a product
            'quantity':  this.cart_items[i].quantity,
            'brand': 'Visages',				// brand/vendor of a product
            'category': 'Eyewear',			// category of a product
            //'variant': 'Silver',       		// product variant. If there are no variants, exclude this key from the dataLayer.push
           
        })
        }
        

        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.user_info',	
            'user_properties': {
            'customer_email': this.order.user.email,
            'user_id': this.order.user.id,
            'customer_first_name': this.order.user.name,
            'customer_last_name': this.order.user.surname,
            'customer_phone': this.order.user.phone,
            'customer_city': this.order.address.city,
            'customer_zip': this.order.address.postcode,
            'customer_address_1': this.order.address.address1,
            'customer_address_2': this.order.address.address2,
            'customer_country': this.order.address.country
           // 'customer_province': 'Gujarat',
            },
        });

    
        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.purchase',				// name of an event. In this case, always stays as eec.purchase
            'ecommerce': {							// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
                'currencyCode': 'EUR',		// the currency which is currently used by a visitor
                'purchase': {						// name of an action. In this case, always stays as purchase
                    'actionField': {
                        'id': this.order.id,                // order id. required 
                        //'affiliation': 'affiliate',	// name of the affiliate. or affiliate id
                        'revenue': this.total,         // order revenue without taxes
                        'tax':'0.00',				// taxes (excluding shipping)
                        'shipping': '0.00'	// shipping cost 
                        //'coupon': 'SUMMER20'     	// coupon code used in this transaction
                    }, 
                    'products': cart_items_datalayer
                }	
        }
        });

    }
            
      

      this.onlycollab=true;     
      for (let i=0;i<this.cart_items.length;i++)
      {
            if(this.cart_items[i].glass.type=="accessoire")
          {
           
              
            this.photos[i]=this.cart_items[i].glass.photos[0]
              
          
          }
          if(this.cart_items[i].glass.type=="collaboration")
          {
            if(this.cart_items[i].product_variation_item!=null)
              {
                  this.photos[i]=this.cart_items[i].product_variation_item.photos[0]
              }
              else
              {
                  this.photos[i]=this.cart_items[i].glass.photos[0]
              }
          
          }
          else
          {
            this.onlycollab=false;
         
            // if(this.cart_items[i].tint!==null)
            // {

            //     var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint='+this.cart_items[i].tint.id;
            // }
            // else
            // {
            //     var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint=5';
            // }
        
            // let photos= await APIRequest.list_with_request('photos',request)
            // let photo=photos[0]

            // this.photos[i]=photo[0].photo

          
            if(this.cart_items[i].glass_type!=null) {
                if(this.cart_items[i].glass_type.id !=5 && this.cart_items[i].prescription==null) {
                    emitter.emit('display-prescription-popup', this.cart_items[i].id, true)

                }
            }
            

          }
          

      }  
      if(this.order.promotion)
      {
          var amount_promotion=(this.order.amount/100) * this.order.promotion.amount;
        if(this.order.promotion.amount==100)
        {
        
            var new_total=0;
        }
        else {
            var new_total=this.order.amount - amount_promotion;
        }
          
          
          jQuery('.order-review #total').html(new_total.toFixed(2));
          jQuery('.order-review #subtotal').html(this.order.amount);
          jQuery('.order-review #total_promotion').html(amount_promotion.toFixed(2));
          jQuery('.order-review .promotion_content').css('display','block');
          jQuery('.checkout .bottom .total_cart #total_cart').html(new_total.toFixed(2));
          jQuery('.total-payment #amount').html(new_total.toFixed(2));

      }
      else
      {
      
          jQuery('.order-review #total').html(this.order.amount);
          jQuery('.checkout .bottom .total_cart #total_cart').html(this.order.amount);
          jQuery('.total-payment #amount').html(this.order.amount);
      
      
      }
      
      this.loaded=true

      this.timer = setInterval(async () => {
            await this.checkOrderStatus()
        }, 10000)
     
  }
}
  
}
</script>
<style>
@media screen and (max-width:760px)
{
    .confirm-wrapper .order-review .review-elem
    {
        display: none;
    }
    .step .image::after {
       width:1px !important;
       top:0 !important;
       left:50% !important;
       height:100% !important;
    }
    .step .image {
        display: table-cell;
        width:50px;
        padding-top:30px;
        padding-bottom: 30px;
    }
    .step-name
    {
        vertical-align: middle;
        display: table-cell;
        text-align: left;
    }
    .steps .step 
    {
        display:table;
       
        width:100% !important;
    }
    .steps{
        
        flex-direction: column;
    }
    body div.payment-confirmation
    {
        order:1;
        width:100% !important;
        max-width:100% !important;
    }
    .confirm-wrapper {
        flex-direction: column;
    }
    .confirm-wrapper .order-review {
        order:2;
        margin-top:0px !important;
        margin-bottom:50px !important;
        height:auto !important;
        min-height: 100px !important;
        width:90% !important;
        margin-left: 5% !important;
        max-width:90% !important;
        background: #f1f1f1 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #0000004B;
    }
}
a
{
    color:#000;
}
.button-white a
{
    color:#fff;
    text-decoration: none;
}
.button-white span
{
    position: relative;
    color: #fff;
    top: -5px;
    font-size: 20px;
    margin-left: 10px;
}
.button-white img{
    display: inline-block;
}
.button-white {
    font-size:20px;
    margin-top: 20px;
    color: #fff;
    background: #000;
    display: inline-block;
    padding: 8px 40px;
    border-radius: 40px;
    cursor: pointer;
}
.step.active .step-name {
    font-weight: 600;
}
.step-name {
    font-size:16px;
}
.payment-confirmation .step {
    width:33%;
    text-align: center;
    min-height: 90px;
}

.step .image::after{
    height: 1px;
    content:" ";
    width:100%;
    position: absolute;
    top:50%;
    left:0;
    background:#000;
}
.step.active .image .round {
    background-color: #000;
}
.order-step .content-step {
    padding-left:85px;
    font-size:15px;
   
    padding-top:15px;
    padding-bottom:20px;
}
.step.active .image .round img
{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.step .image .round {
    position:absolute;
    z-index: 2;
    left:50%;
    top:50%;
    width:22px;
    height:22px;
    border:1px solid black;
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%,-50%);
}
.order-steps-title .content .subtitle {
    font-size:22px;
   
}
.order-steps-title .content .small {
    font-size:16px;
    font-weight: 400;
}
.order-step .content {
    display:inline-block;
    vertical-align: top;
    width: calc(100% - 65px);
}
.order-step.opened .title-step::after
{
    content:"-";
  
    
}
.title-step::after
{
    content:"+";
    position:absolute;
    right:20px;
    top:17px;
    
}
.title-step{
    font-size:16px;
    cursor: pointer;
    position: relative;
}
.order-steps-title .content {
    display:inline-block;
    vertical-align: top;
    width: calc(100% - 61px);
}
.order-steps-title {
  margin-top:30px;
  font-size:28px;
  
  font-weight: 600;
  margin-bottom:30px;
  
}
.order-step:nth-child(2) .title-step{
    border-top:1px solid #757272;
}
.title-step { 
    padding:17px 20px 4px 20px;
    border-bottom:1px solid #757272;
}
.title-step .num {
    width:36px;
    position: relative;
    top:-5px;
    vertical-align: top;
    height:36px;
    padding-top:5px;
    text-align: center;
    border:1px solid black;
    margin-right:25px;
    vertical-align: top;
    display: inline-block;
    border-radius: 50%;
}
.order-steps-title .arrow {
    width:36px;
    position:relative;
    top:2px;
    margin-right:25px;
    vertical-align: top;
    display: inline-block;
}
.message {
    font-size:18px;
}
.step .image
{
    position: relative;
    height:30px;
    margin-bottom:20px;
}
.steps {
    margin-top:30px;
    margin-bottom:30px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.payment-confirmation .title {
    font-size:38px;
    
}
.payment-confirmation .title .b {
    font-weight:600;
    
}
h2.confirm-title
{
font-size: 20px;
    font-weight: 500;
text-align:left;
}
.payment-confirmation h2.confirm-title
{
font-size: 20px;
    font-weight: 500;
text-align:left;
}
.confirm-wrapper {
display:flex;
}

body div.payment-confirmation
{
width:45%;
max-width:50%;
padding:5%;

}
.subtotal
{
	clear:both;
	display:block;
	width:100%;
}
.subtotals
{
	padding-top:20px;
	padding-bottom:40px;
	border-top:2px solid #eee;
	border-bottom:2px solid #eee;
}

.subtotal .subtotal-amount
{
	float:right;
}
.subtotal .subtotal-label
{
	float:left;
}
</style>